import axios from "@/plugins/axios.js"

const actions = {
  fetchStages({ commit},  payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/stages?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_ITEMS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchAllStages({ commit, state }, force = false) {
    return new Promise((resolve, reject) => {
      if(!force && state.list.length) return resolve(true)
      axios.get("/stages/all")
        .then((response) => {
          if(response.data.success){
            commit('SET_ALL_ITEMS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}

const getters = {

}

const state = {
  list:[],
  pageData:{
    data:[]
  }
}

const mutations = {
  SET_ALL_ITEMS(state, list){
    state.list = list
  },
  SET_ITEMS(state, data){
    state.pageData = data
  },
  ADD_ITEM(state, item){
    state.pageData.data.unshift(item);
  },
  UPDATE_ITEM(state, item){
    const dataIndex = state.pageData.data.findIndex((i) => i.id == item.id)
    Object.assign(state.pageData.data[dataIndex], item)
  },
  DELETE_ITEM(state, itemId){
    const ItemIndex = state.pageData.data.findIndex((item) => item.id == itemId)
    state.pageData.data.splice(ItemIndex, 1);
  },

}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

