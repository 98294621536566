
import axios from "@/plugins/axios.js"
import { useToast } from 'vue-toastification'
const toast = useToast()

const actions = {
  alertSuccess({}, message) {
      toast.success(message)
  },
  alertError({}, message) {
      toast.error(message)
  },
  changeLoaderValue({ commit }, val = false) {
    commit("TOGGLE_LOADER", val)
  },
  logoutUser({ commit, rootState }, action) {
    commit("LOGOUT_USER")
    return new Promise((resolve, reject) => {
      axios.get(`${rootState.domain}/api/logout`)
      .then((response) => {
        resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  universalFetch({ commit, rootState }, action) {
    return new Promise((resolve, reject) => {
      axios.get(action)
      .then((response) => {
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  serverExport({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(payload.url, { responseType: 'blob' })
      .then((response) => {
        resolve(response)
        if(response.status == 200){
          const timestamp = new Date().getTime();
          const filename =  (payload.filename || '') + timestamp + '.xlsx';
          
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        }
      }).catch((error) => { reject(error) })
    })
  },
  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////
  fetchActiveUser({ commit, rootState }, action) {
    return new Promise((resolve, reject) => {
      axios.get("/account/show")
      .then((response) => {
          if(response.data.success){
            commit('UPDATE_USER_INFO', response.data.data)
          }
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  fetchCountries({ commit, rootState }) {
    if(rootState.countries.length) return
    return new Promise((resolve, reject) => {
      axios.get(`${rootState.domain}/api/countries`)
        .then((response) => {
          if(response.data.success){
            commit('SET_COUNTRIES', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  }
}

export default actions
