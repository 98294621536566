export default{
  methods:{
      absoluteUrl(path = ""){
        return this.$store.state.domain+path
      },
      isImageFile(ext){
        return  ['png','jpeg','svg','jpg','webp'].includes(ext ? ext.toLowerCase() : null)
      },
      preText(content) {
        if(!content) return;
        const words = content.replace(/\n/g, ' <br>').split(' ');
        const formattedWords = [];
        words.forEach(word => {
          if (word.startsWith('http://') || word.startsWith('https://')  || word.startsWith('www.')) {
            formattedWords.push(`<a href="${word}" class="text-primary" target="_blank"> ${word} </a>`);
          } else {
            formattedWords.push(word)
          }
        });
        return formattedWords.join(' ')
      },
      statusBadge(status, color){
        if(!status) return;
        const codes = { pending: '#EA5455', checkedin: '#EA5455', checkedout: '#007d53', active : '#0ec569', reviewed : '#41d051',
                   settled: '#0ec569',  dismissed : '#ff0002', closed  : '#ff0002',
                  draft: '#222222',  approved: '#007d53', canceled: '#FF0000',
                  suspended: '#ff0002', resolved: '#000000',  rejected  : '#ff0002',
                  appealed : '#41d051', removed: '#ff0002', deactivated: '#ff0002'
                }
        const statusColor = color || codes[status.toLowerCase()];
        const statusTitle = status.replace(/_/g, ' ')
        
        return `<span style="background:${statusColor}24; color:${statusColor}; padding:0px 5px 2px 5px;line-height:1; border-radius:4px;">${statusTitle}</span>`
      },
      scrollToView(elID){
        if(!elID){return}
        const yOffset = -200;
        const element = document.getElementById(elID);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
      },
      alertSuccess(text){
        this.$store.dispatch("alertSuccess", text)
      },
      alertError(text){
        this.$store.dispatch("alertError", text)
      },
      confirmDelete(option){
        return new Promise((resolve, reject) => {
          Swal.fire({
            title: option.title || "Are you sure?",
            html: option.text,
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: "#f1556c",
            cancelButtonColor: "#111",
            confirmButtonText: "Yes, Proceed!",
          }).then((result) => {
            resolve(result)
          }).catch((error) => { reject(error) });
        })
      },
      confirmAction(option){
        return new Promise((resolve, reject) => {
          Swal.fire({
            title: option.title || "Confirm action!",
            html: option.text,
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: "#225862",
            cancelButtonColor: "#111",
            confirmButtonText: "Yes, Proceed!",
          }).then((result) => {
            resolve(result)
          }).catch((error) => { reject(error) });
        })
      }
  },
}
