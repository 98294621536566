
import axios from "@/plugins/axios.js"

const actions = {
  fetchLawyers({ commit, state}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/lawyers?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_ITEMS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchAllLawyers({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get("/lawyers/all")
        .then((response) => {
          if(response.data.success){
            commit('SET_ALL_ITEMS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}

const mutations = {
  SET_ALL_ITEMS(state, list){
    state.list = list
  },
  SET_ITEMS(state, data) {
    state.pageData = data
  },
  DELETE_ITEM(state, itemId) {
    state.user = {}
    const userIndex = state.pageData.data.findIndex((u) => u.id == itemId)
    state.pageData.data.splice(userIndex, 1)
  },
}

  
const getters = {
   
}

const state = {
    pageData: {
      data: []
    },
    list:[],
}

export default {
    isRegistered: true,
    namespaced: true,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions,
}
  