export default [
    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
        path: '/main',
        redirect: '/',
        component: () => import('@/layouts/Main.vue'),
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: () => import('@views/Dashboard.vue'),
                meta: {
                  title: 'Dashboard',
                  authRequired: true,
                }
            },
            {
                path: '/account',
                name: 'account',
                component: () => import('@views/account/UserEdit.vue'),
                meta: {
                  title: 'Account',
                  authRequired: true,
                },
            },
            {
              path: '/department',
              name: 'department',
              component: () => import('@views/departments/Department.vue'),
              meta: {
                title: "Department",
                authRequired: true,
              }
            },
            {
              path: '/cases',
              name: 'cases',
              component: () => import('@views/cases/CaseList.vue'),
              meta: {
                title: "Cases",
                authRequired: true,
              }
            },
            {
              path: '/cases/:caseId(\\d+)',
              name: 'case-show',
              component: () => import('@views/cases/CaseShow.vue'),
              meta: {
                title: "View Case",
                authRequired: true,
              },
            },
            {
                path: '/lawyers',
                name: 'lawyers-list',
                component: () => import('@views/lawyers/LawyerList.vue'),
                meta: {
                  title: "Lawyers List",
                  authRequired: true,
                },
            },
            {
              path: '/lawyers/:lawyerId(\\d+)',
              name: 'lawyer-view',
              component: () => import('@views/lawyers/LawyerShow.vue'),
              meta: {
                title: "View Lawyer",
                authRequired: true,
              },
            },
            {
              path: '/notifications',
              name: 'notifications',
              component: () => import('@views/notifications/NotificationList.vue'),
              meta: {
                title: "Notifications",
                authRequired: true,
              }
            }
        ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '/full',
        component: () => import('@/layouts/Full.vue'),
        children: [
          {
            path: '/login',
            name: 'login',
            component: () => import('@/views/pages/Login.vue'),
            meta: {
              guestRequired: true,
              title: "Login"
            }
          },
          {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/pages/Error404.vue'),
            meta: {
              title: "404 - Page not Found"
            }
          },
          {
            path: '/error-500',
            name: 'error-500',
            component: () => import('@/views/pages/Error500.vue'),
            meta: {
              title: "500 - Server error"
            }
          },
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '/:any(.*)',
        redirect: '/error-404'
    },

]