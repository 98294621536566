
import { createStore } from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

import moduleLayout from "./modules/layout.js"
import moduleLawyers from './modules/lawyers.js'
import moduleNotifications from "./modules/notifications.js"
import moduleCaseCategory from './modules/case-categories.js'
import moduleJudge from './modules/judges.js'
import moduleCourt from './modules/courts.js'
import moduleCase from './modules/cases.js'
import moduleStage from './modules/stages.js'
import moduleCaseStatus from './modules/case-statuses.js'

const store = createStore({
    state,
    actions,
    getters,
    mutations,
    modules: {
        layout: moduleLayout,
        lawyer: moduleLawyers,
        notification: moduleNotifications,
        caseCategory: moduleCaseCategory,
        judge: moduleJudge,
        court: moduleCourt,
        case: moduleCase,
        stage: moduleStage,
        caseStatus: moduleCaseStatus
    },
    strict: process.env.NODE_ENV !== 'production',
})

export default store
